import { SuiteAddressType, SuiteDetailsType, SuiteState, SuiteValueHistoryType } from './types';

const selectSuiteData = (state): SuiteState => state.suite;
const selectSuiteAddress = (state): SuiteAddressType => state.suite.address;
const selectSuiteDetails = (state): SuiteDetailsType => state.suite.details;
const selectSuiteValues = (state): SuiteValueHistoryType[] => state.suite.valueHistory;

export const selectors = {
    selectSuiteData,
    selectSuiteAddress,
    selectSuiteDetails,
    selectSuiteValues,
};
