import { toMoney } from '@keyliving/utils';

import { Section } from '../../../../components/Section';
import { SectionDivider } from '../../../../components/Section/SectionDivider';
import { SectionHeader } from '../../../../components/Section/SectionHeader';
import { SectionItem } from '../../../../components/Section/SectionItem';
import { useSelector } from '../../../../redux/hooks';
import { selectors as financeSelectors } from '../../../../redux/modules/finance';
import { selectors as suiteSelectors } from '../../../../redux/modules/suite';
import styles from './CoOwnershipInterest.module.scss';

export function CoOwnershipInterest(): JSX.Element {
    const { value } = useSelector(suiteSelectors.selectSuiteData);
    const { current_equity_value, total_co_financing_allocated } = useSelector(
        financeSelectors.selectFinancialCapTableCurrent
    );

    return (
        <Section>
            <SectionHeader header="CURRENT VALUES" />

            <SectionItem
                label="Current Home Value"
                value={toMoney(value, 0, {
                    maximumFractionDigits: 0,
                })}
                valueSize="large"
            />

            <SectionDivider className={styles.sectionDivider} />

            <div className={styles['values-grid']}>
                {/* <SectionItem
                    label="Total Co-Ownership Interest"
                    value={toMoney(current_equity_value)}
                    valueSize="medium"
                /> */}
                {/* <SectionItem
                    label="Co-Ownership Percent"
                    value={toPercent(current_co_ownership_interest_percentage)}
                    valueSize="medium"
                /> */}
                <SectionItem
                    label="Your Equity Value"
                    value={toMoney(current_equity_value - total_co_financing_allocated, 0, {
                        maximumFractionDigits: 0,
                    })}
                    valueSize="medium"
                />
                {/* <SectionItem
                    label="Your Co-Financing"
                    value={toMoney(total_co_financing_allocated)}
                    valueSize="medium"
                /> */}
            </div>
        </Section>
    );
}
