import PageContainer from '../../components/PageContainer';
import { ContactKey } from './ContactKey';
import { Faq } from './Faq';
import styles from './Page.module.scss';

export default function Page(): JSX.Element {
    return (
        <>
            <div className={styles.header}>
                <div>Help</div>
            </div>
            <PageContainer>
                <div className={styles.body}>
                    <div>
                        <Faq />
                    </div>
                    <div>
                        <ContactKey />
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
