import { useDisableScroll, useGlobalAuth } from '@keyliving/component-lib';
import { Menu } from '@material-ui/icons';
import classnames from 'classnames/bind';
import { useCallback, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { KeyLogo, Profile, Question, Wallet as WalletIcon } from '../../assets/icons';
import useAnalytics from '../../hooks/useAnalytics';
import { useAppDispatch } from '../../redux/hooks';
import { actions as authActions } from '../../redux/modules/auth';
import { Button } from '../UI/Button';
import styles from './NavBar.module.scss';

const cx = classnames.bind(styles);

export function NavBar(): JSX.Element {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [blockScroll, allowScroll] = useDisableScroll();
    const { trackEvent } = useAnalytics();
    const { deleteToken: deleteAuthToken } = useGlobalAuth();

    const handleLogout = () => {
        deleteAuthToken();
        dispatch(authActions.onRequestLogout());
        sessionStorage.clear();
        trackEvent('logout');
        navigate('/login');
    };

    /**
     * We need to close the menu when the user navigates. This is
     * a Band-aid for now.
     *
     * TODO: Better solution than passing closeMenu to every
     * NavLink's onClick handler. Context?
     */
    const closeMenu = useCallback(() => {
        setIsOpen(false);
    }, []);

    const toggleMenu = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            blockScroll();
        }

        return allowScroll;
    }, [allowScroll, blockScroll, isOpen]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <Link className={styles.logo} onClick={closeMenu} to="/home">
                    <img alt="Key Logo" src={KeyLogo} />
                </Link>
                <nav
                    className={cx('main-nav-wrapper', {
                        'main-nav-wrapper--open': isOpen,
                    })}
                >
                    <ul className={styles['main-nav']}>
                        <li className={cx('mainNavItem', 'mainNavItem--financials')}>
                            <NavLink onClick={closeMenu} to="/financial">
                                <WalletIcon height={20} width={24} />
                                Your Financials
                            </NavLink>
                            <ul className={styles.dropdown}>
                                <li>
                                    <NavLink
                                        onClick={closeMenu}
                                        to="/financial/co-ownership-interest"
                                    >
                                        Co-Ownership
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={closeMenu} to="/financial/equity-purchase">
                                        Equity Purchase
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={closeMenu} to="/financial/monthly-payment">
                                        Monthly Payment
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className={styles.mainNavItem}>
                            <NavLink onClick={closeMenu} to="/help">
                                <Question height={20} width={20} />
                                Help
                            </NavLink>
                        </li>
                        <li className={styles.mainNavItem}>
                            <NavLink onClick={closeMenu} to="/my-account">
                                <Profile height={20} width={24} />
                                My Account
                            </NavLink>
                        </li>
                        <li className={cx('mainNavItem', 'mainNavItem--logout')}>
                            <Button colour="blue" label="Logout" onClick={handleLogout} />
                        </li>
                    </ul>
                </nav>
                <button className={styles.hamburger} onClick={toggleMenu} type="button">
                    <Menu />
                </button>
            </div>
        </div>
    );
}
